.footer {
	@apply flex flex-col gap-[60px] py-4;
	@apply lg:py-5;
}
.footerContentWrapper {
	@apply flex flex-col gap-[35px] pt-[40px] max-w-[1300px] mx-auto px-4;
	@apply sm:grid sm:grid-cols-[1.25fr,_1fr,_1fr] sm:gap-y-[40px] sm:gap-x-[16px];
	@apply lg:px-5 lg:grid lg:grid-cols-[1.25fr,_0.75fr,_0.75fr,_1.25fr] lg:gap-x-[32px];
	@apply xl:gap-x-[48px];
}

.logoContainer {
	@apply relative inline-block h-9 max-w-full aspect-[5/1];
}

.firstBox p {
	@apply mb-[10px] text-balance text-[16px] leading-normal text-white-800;
	@apply ssm:text-[18px];
	@apply sm:pr-[10px];
	@apply sm:pr-[10px];
}

.firstBox ul {
	@apply mt-[20px] flex justify-start items-center gap-[18px];
}

.firstBox ul a {
	@apply flex  w-[50px] h-[50px] items-center justify-center bg-white-100 rounded-full;
	@apply hover:bg-primary;
	transition: background-color 0.5s;
}

.firstBox svg {
	transition: stroke 0.5s;
}

.socialMediaLink:hover svg {
	@apply stroke-black;
}

.footerItem h2,
.logoContainer {
	@apply mb-[30px];
	@apply sm:mb-[16px] sm:h-[32px];
}

.footerItem h2 {
	@apply text-[20px] font-semibold font-jakarta;
}

.footerItem ul {
	@apply flex flex-col gap-[15px] capitalize text-[16px] text-white-800 leading-normal;
	@apply ssm:text-[18px];
}

.footerItem ul a {
	@apply hover:text-white text-nowrap;
	transition: color 0.3s;
}

.footerNewsLetter input {
	@apply w-full h-[38px] bg-eerieBlack-light border-none rounded-[10px] mb-[23px] py-[28px] pl-[24px] text-[16px] leading-[1.42857];
	@apply hover:bg-eerieBlack-light-hover;
	transition: background-color 0.3s;
}

.footerNewsLetter input::placeholder {
	@apply text-white-400;
}

.footerNewsLetter button {
	@apply inline-block w-auto py-[15px] px-[30px] bg-primary text-black text-[20px] font-jakarta font-semibold hover:bg-white rounded-full;
	@apply focus:bg-white;
	transition: background-color 0.3s;
}

.footerCopyright {
	@apply text-center;
}
