/* header.module.css */

/* Estilos existentes */
.header {
	@apply z-[35] fixed left-2/4 top-0 w-full -translate-x-2/4 bg-eerieBlack;
}

.headerWrapper {
	@apply max-w-[1300px] mx-auto;
	@apply lg:flex lg:justify-between lg:p-5;
}

.headerContentWrapper {
	@apply z-40 relative flex justify-between items-center bg-eerieBlack p-4;
	@apply lg:p-0;
}

.logoContainer {
	@apply z-50 relative h-8 aspect-[5/1] mr-4 ml-2;
	@apply md:ml-0;
	@apply lg:h-10;
}

.logoContainer img {
	@apply pointer-events-none;
}

/* Estilos para el botón del menú móvil */
.navToggleButton {
	@apply z-50 min-h-12 min-w-12 p-[17px] bg-primary rounded-[10px] flex items-center;
	@apply focus:bg-white hover:bg-white;
	@apply lg:hidden;
	transition: background-color 0.3s;
	cursor: pointer;
}

.MobileNavIcon {
	@apply relative h-[2px] w-full bg-eerieBlack rounded-full;
	transition: background-color 0.3s 0.15s;
}

.MobileNavIcon::after {
	content: '';
	@apply absolute top-2/4 left-0 w-full h-[2px] bg-eerieBlack rounded-full translate-y-[3px];
	transition: all 0.3s;
}

.MobileNavIcon::before {
	content: '';
	@apply absolute top-2/4 left-0 w-full h-[2px] bg-eerieBlack rounded-full -translate-y-[5px];
	transition: all 0.3s;
}

/* Estado abierto del icono del menú móvil */
.MobileNavIconOpen {
	@apply bg-transparent;
	transition: background-color 0.15s;
}

.MobileNavIconOpen::before {
	@apply translate-y-0 rotate-45;
	transition: all 0.3s 0.15s;
}

.MobileNavIconOpen::after {
	@apply translate-y-0 -rotate-45;
	transition: all 0.3s 0.15s;
}

/* Estilos del menú de navegación */
.headerNavigation {
	@apply absolute bottom-[80px] left-0 w-full py-4 invisible opacity-0 pointer-events-none bg-black text-center text-white transform duration-300;
	@apply lg:relative lg:w-max lg:p-0 lg:left-auto lg:bottom-auto lg:opacity-100 lg:visible lg:pointer-events-auto lg:bg-transparent lg:text-left;
}

.headerNavigationOpen {
	@apply visible opacity-100 pointer-events-auto translate-y-[calc(100%+80px)];
}

.headerNavigationList {
	@apply min-w-full;
	@apply lg:flex lg:gap-11 lg:items-center lg:min-w-min;
}

.navListItem {
	@apply w-full;
	@apply lg:w-auto;
}

.headerNavigationLink {
	@apply inline-block w-full p-5 text-[20px];
	@apply lg:p-0;
}

.navLinkActive {
	@apply text-primary;
}

.headerNavigationList li:last-child .headerNavigationLink {
	@apply bg-white w-auto py-2 px-4 rounded-full mt-[10px] mb-6 text-black text-2xl font-semibold;
	@apply lg:m-0 lg:py-3 lg:px-7 lg:text-[22px];
}
